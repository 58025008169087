<template>
  <div>
    <router-link :to="{ name: 'edit-collection', params: { id: folder.id } }" @click="close">
      <ion-item class="item-container clickable-item-hov">
        <div class="menu-item">
          <small>Edit Folder</small>
        </div>
      </ion-item>
    </router-link>
    <ion-item @click="deleteCurrentCollection" class="item-container clickable-item-hov">
      <div class="menu-item">
        <small>Delete Folder</small>
      </div>
    </ion-item>
  </div>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import { deleteCollection } from '@/shared/actions/collections';
import { toast } from '@/shared/native/toast';
import { Character } from '@/shared/types/static-types';

const props = defineProps({
  instance: {
    type: Object,
    default: () => ({}),
  },
  folder: {
    type: Object,
    default: () => ({}),
  },
});

const close = () => props.instance?.dismiss();
const folder = computed(() => props.folder as Character);

const deleteCurrentCollection = async () => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to delete this collection.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-secondary',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      const router = useRouter();
      await deleteCollection(folder.value.id || '');
      await toast.show('Folder has been deleted.', 'nonative', 'primary');
      router.push({ name: 'my-creations', query: { tab: 'folders' } });
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  } finally {
    close();
  }
};
</script>

<style scoped lang="sass">
.bottom-button
  background-color: var(--ion-color-danger) !important
  color: white
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem

.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

hr
  margin-bottom: 0
</style>
