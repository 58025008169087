<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="true" :show-backdrop="true" @didDismiss="dismissModal">
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="main mt-1 d-flex flex-column overflow-auto">
        <div v-if="!loading">
          <div class="mt-3">
            <div class="d-flex align-items-center">
              <i class="icon pl-2 ti-search position-absolute" />
              <ion-input
                class="c-input one-input"
                :value="searchValue"
                placeholder="Search your folders"
                @input="searchValue = $event.target.value"
              />
            </div>
          </div>
          <div class="mt-2">
            <div class="d-flex justify-content-end">
              <ion-button :color="buttonColor" class="reorder-button" @click="toggleReorder">
                {{ buttonContent }}
              </ion-button>
            </div>

            <Grid scrollable>
              <Sortable
                item-key="id"
                tag="div"
                class="sorting"
                :class="{ dov: isReordering }"
                :list="alreadySelectedFolders"
                :options="sortableOptions"
                @end="handleReorder"
              >
                <template #item="{ element: folder, index }">
                  <FolderCard
                    :key="index"
                    class="selectable-grid-item"
                    :folder="folder"
                    :is-selected="isFolderSelected(folder)"
                    image-only
                    is-selectable
                    is-removable
                    :is-reorderable="isReordering"
                    @select="select(folder, $event)"
                  />
                </template>
              </Sortable>
            </Grid>
          </div>
          <div v-if="presentedFolders.length" class="selectable-characters mb-3 d-flex align-items-center">
            <Grid :lg="4" :md="3" :sm="2" :scrollSm="false">
              <FolderCard
                v-for="(oneFolder, index) of presentedFolders"
                :key="index"
                class="grid-item"
                :folder="oneFolder"
                is-selectable
                :is-selected="isFolderSelected(oneFolder)"
                limited
                @select="select(oneFolder, $event)"
              />
            </Grid>
          </div>
          <div v-else class="no-data">No folders</div>
        </div>
        <div v-else class="d-flex align-items-center justify-content-center">
          <ch-loading size="lg" />
        </div>
      </div>
      <div class="d-flex mt-1">
        <ion-custom-button
          :disabled="isSubmitting"
          :loading="isSubmitting"
          color="primary"
          class="mt-3 mr-2 action-btn"
          @click.stop="onSubmit"
          >Save</ion-custom-button
        >
        <ion-custom-button :disabled="isSubmitting" color="medium" class="mt-3 action-btn" @click="dismissModal"
          >Cancel</ion-custom-button
        >
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts" setup>
import { Collection } from '@/shared/types/static-types';
import {
  addFoldersInsideFolder,
  getCharacterCollections,
  removeObjectInCollection,
} from '@/shared/actions/collections';
import { isTouchScreen } from '@/shared/utils/ui';
import { toast } from '@/shared/native/toast';
import { authStore } from '@/shared/pinia-store/auth';
import Grid from '@/shared/components/storage/Grid.vue';
import FolderCard from '@/shared/components/storage/FolderCard.vue';

const searchValue = ref('');

const presentedFolders = computed(() =>
  searchValue.value
    ? foldersToSelectFrom.value.filter((folder) => folder.slug?.toLocaleLowerCase().includes(searchValue.value))
    : foldersToSelectFrom.value
);

const handleReorder = (e: any) => {
  const { oldIndex, newIndex } = e;
  const element = alreadySelectedFolders.value[oldIndex];
  alreadySelectedFolders.value.splice(oldIndex, 1);
  alreadySelectedFolders.value.splice(newIndex, 0, element);
};

const sortableOptions = ref({
  handle: '.reorder-handle',
  animation: 150,
  delay: isTouchScreen() === false ? 0 : 200,
});

const buttonContent = computed(() => (isReordering.value ? 'Save order' : 'Reorder'));
const buttonColor = computed(() => (isReordering.value ? 'success' : 'primary'));

const props = defineProps({
  modalTitle: {
    type: String,
    default: 'Add subfolders to folder',
  },
  isOpen: {
    type: Boolean,
  },
  folder: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
  },
  folders: {
    type: Array,
    default: () => [],
  },
});

const isReordering = ref(false);
const innerLoading = ref(false);
const foldersRef = toRef(props, 'folders');

const toggleReorder = async () => {
  try {
    isReordering.value = !isReordering.value;
    innerLoading.value = true;
    const previouslySelectedFolderIds = foldersRef.value.map(({ id }: any) => id);
    const selectedFolderIds = alreadySelectedFolders.value.map(({ id }: any) => id);
    if (!isReordering.value) {
      await removeObjectInCollection({
        collection: folderRef.value.id,
        ids: previouslySelectedFolderIds,
      });
      await addFoldersInsideFolder(folderRef.value.id, selectedFolderIds);
    }

    innerLoading.value = false;
  } finally {
    innerLoading.value = false;
  }
};

const emits = defineEmits(['dismissModal', 'reload']);

const alreadySelectedFolders = ref<Collection[]>([]);
const folderRef = toRef(props, 'folder');

const { user, isAuthenticated } = authStore();

const areFoldersLoading = ref(false);

const isSubmitting = ref(false);

watch(
  foldersRef,
  () => {
    alreadySelectedFolders.value = cloneDeep(foldersRef.value as Collection[]);
  },
  { deep: true }
);

const userFolders = ref<Collection[]>([]);

const dismissModal = () => {
  emits('dismissModal');

  searchValue.value = '';
  isSubmitting.value = false;
};

const isFolderSelected = (folder: Collection) => {
  return alreadySelectedFolders.value.map(({ id }) => id).includes(folder?.id!);
};

const select = (folder: Collection, isSelected: boolean) => {
  let edited = cloneDeep(alreadySelectedFolders.value);
  if (isSelected) {
    edited.push(folder!);
  }

  if (!isSelected) {
    edited = alreadySelectedFolders.value.filter(({ id: folderId }) => folderId !== folder.id);
  }

  alreadySelectedFolders.value = edited;
};

const onSubmit = async () => {
  isSubmitting.value = true;
  const previouslySelectedFolderIds = foldersRef.value
    .map((item: any) => item.id)
    .filter((item): item is string => !!item);
  const selectedFolderIds = alreadySelectedFolders.value.map(({ id }: any) => id);

  try {
    if (previouslySelectedFolderIds.length) {
      await removeObjectInCollection({
        collection: folderRef.value.id,
        ids: previouslySelectedFolderIds,
      });
    }

    await addFoldersInsideFolder(folderRef.value.id, selectedFolderIds);

    dismissModal();
    emits('reload');
  } catch (_err) {
    await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    dismissModal();
  }

  isSubmitting.value = false;
};

const fetchUserFolders = async () => {
  areFoldersLoading.value = true;

  const res = await getCharacterCollections(user.value.id);
  userFolders.value = res;

  areFoldersLoading.value = false;
};

const foldersToSelectFrom = computed(() => userFolders.value.filter(({ id }: any) => id !== folderRef.value.id));

onMounted(() => {
  if (isAuthenticated.value) {
    fetchUserFolders();
  }
});
</script>

<style lang="sass" scoped>
.sorting
  grid-gap: 6px
  display: flex
.submit-btn
  width: 180px
  .spinner
    width: 15px
    height: 15px

ion-modal
  --width: 60%
  --height: 85%
  @media(min-width: 1100px)
    --width: 45% !important
  @media(max-width: 599px)
    --height: 85%
    --width: 85%

.main
  height: calc(100% - 3.5rem)
  overflow-x: hidden !important

.action-btn
  width: 90px !important
  margin-top: 0 !important
  ::v-deep
    *
      text-transform: unset !important
    .button
      height: 40px !important
</style>
